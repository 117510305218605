import React, {
  FunctionComponent,
  Fragment,
  useState,
  memo,
  Dispatch,
} from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { useMutation } from "urql"
import {
  ASN_RELATIONSHIP,
  AsnRelationship,
  formatAsnRelationship,
} from "@models/asn-relationship"
import { SET_ALERT } from "@store/navigation-store"
import { ReducerAction } from "@store/index"
import { useDispatch } from "react-redux"

interface Props {
  isOpen: boolean
  closeModal: () => void
  asn: number
  onSubmit: () => void
  nome: string
  email: string
}

export interface AsnDetailsFormFields {
  email: string
  telefone: string
  associacaoParticipante: string
  asn: number
  nome: string
  asnRelationship: AsnRelationship
}

const AsnDetailsModal: FunctionComponent<Props> = ({
  isOpen,
  closeModal,
  asn,
  onSubmit,
  nome,
  email,
}) => {
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  const initialModalData: AsnDetailsFormFields = {
    email: email,
    telefone: "",
    associacaoParticipante: "",
    asn: 0,
    nome: nome,
    asnRelationship: ASN_RELATIONSHIP[0], // DONO_ASN
  }

  const [formState, setFormState] = useState<AsnDetailsFormFields>({
    ...initialModalData,
    asn: asn,
  })

  const handleCloseModal = () => {
    closeModal()
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target
    setFormState((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const [_, executeMutation] = useMutation(
    `mutation (
        $asn: Int!, 
        $asnRelationship: AsnRelationship!, 
        $associacaoParticipante: String!, 
        $email: String!, 
        $nome: String!, 
        $telefone: String!
    ){
        addAsnDetails(
            asn: $asn
            asnRelationship: $asnRelationship
            associacaoParticipante: $associacaoParticipante
            email: $email
            nome: $nome
            telefone: $telefone
        ) {
            success
            message
        }
    }`
  )

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault()
    executeMutation(formState)
      .then((result) => {
        if (result.error) {
          console.error("error ao fazer mutation: ", result.error.message)
          if (result.error.message.includes("E_TIT_UNAUTHORIZED_ACCESS_ASN")) {
            dispatch({
              type: SET_ALERT,
              alert: {
                message_key: "graphql_error:E_TIT_UNAUTHORIZED_ACCESS_ASN",
              },
            })
          }
          return
        }
        if (result.data.success) {
          dispatch({
            type: SET_ALERT,
            alert: {
              type: "SUCCESS",
              message_key: "success:resource_created",
            },
          })
        }
      })
      .finally(() => {
        setFormState(initialModalData)
        onSubmit()
      })
  }

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-20 inset-0"
          onClose={handleCloseModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0" />
          </Transition.Child>
          <div className="fixed flex z-20 inset-0 items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative border transform overflow-hidden text-left rounded-lg bg-white px-4 pt-5 pb-4 shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="flex">
                  <Dialog.Title className="mx-auto mb-10 text-slate-700 text-center font-semibold py-1">
                    Informações Adicionais do ASN {asn}
                  </Dialog.Title>
                  <div className="items-center justify-center right-0 top-0 pt-1 pr-6 pl-5 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => handleCloseModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  {/* <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                      value={formState.email}
                      onChange={handleInputChange}
                      // required
                    />
                  </div> */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="telefone"
                    >
                      Telefone
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="telefone"
                      type="tel"
                      //   pattern="^\([1-9]{2}\) (?:[2-8]|9[0-9])[0-9]{3}\-[0-9]{4}$"
                      pattern="^(\(?[0-9]{2}\)?)? ?([0-9]{4,5})-?([0-9]{4})$"
                      name="telefone"
                      placeholder="Telefone"
                      value={formState.telefone}
                      onChange={handleInputChange}
                      // required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="associacaoParticipante"
                    >
                      Associação Participante
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="associacaoParticipante"
                      type="text"
                      name="associacaoParticipante"
                      placeholder="Associação Participante"
                      value={formState.associacaoParticipante}
                      onChange={handleInputChange}
                      // required
                    />
                  </div>
                  <div className="mb-4">
                    {ASN_RELATIONSHIP.map((asnRelationship, idx) => {
                      let radioName = `default-radio-${idx}`
                      return (
                        <div className="flex items-center mb-4">
                          <input
                            checked={
                              formState.asnRelationship == asnRelationship
                            }
                            id={radioName}
                            defaultValue={initialModalData.asnRelationship}
                            type="radio"
                            value={asnRelationship}
                            onChange={handleInputChange}
                            name="asnRelationship"
                            className="w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor={radioName}
                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {formatAsnRelationship(asnRelationship)}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-white hover:bg-gray-50 text-gray-900 text-sm font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline shadow-sm ring-1 ring-inset ring-gray-300 "
                      type="button"
                      onClick={() => {
                        handleCloseModal()
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-auto max-w-4xl"
                      type="submit"
                    >
                      Salvar
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default memo(AsnDetailsModal)
