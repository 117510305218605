// import { AutonomousSystem } from "@models/autonomous-system"
import React, { FunctionComponent } from "react"
import { CheckCircleIcon, BanIcon } from "@heroicons/react/outline"
import { doMeasurementsObeyToVolumeRules } from "@utils/leaderboard-measurements-obey"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  posicao: number
  asn: number
  name: string
  porcentagemAtual: number
  porcentagemAnterior: number
  medicaoIPv6: number
  medicaoIPv4: number
  isAcrescimo: boolean
  classNameTd?: string
  classNameTr?: string
  active: boolean
  conformeRegulamento: boolean
  porcentagemNovembro: number
}

const IndiceVariationIcon: FunctionComponent<{ isHigher: boolean }> = ({
  isHigher,
}) => {
  return isHigher ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="w-5 h-5 text-green-500 inline-block"
    >
      <path
        fillRule="evenodd"
        d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="w-5 h-5 text-red-500 inline-block rotate-180 transform"
    >
      <path
        fillRule="evenodd"
        d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
        clipRule="evenodd"
      />
    </svg>
  )
}

const LeaderBoardRow: FunctionComponent<Props> = ({
  posicao,
  asn,
  name,
  porcentagemAtual,
  porcentagemAnterior,
  medicaoIPv4,
  medicaoIPv6,
  isAcrescimo,
  classNameTr,
  classNameTd,
  active,
  conformeRegulamento,
  porcentagemNovembro,
}) => {
  const verifyHigher = porcentagemAtual > porcentagemAnterior
  return (
    <tr
      className={classNames(
        "whitespace-nowrap text-lg font-semibold text-slate-600",
        classNameTr ?? "",
        active ? "bg-slate-100" : ""
      )}
    >
      <td
        className={classNames(
          "border-b px-3 py-2 border-slate-500",
          classNameTd ?? ""
        )}
      >
        {posicao}
        <IndiceVariationIcon isHigher={verifyHigher} />
      </td>
      <td
        className={classNames(
          "border-b px-3 py-2 border-slate-500",
          classNameTd ?? ""
        )}
      >
        AS{asn}
        <br />
        <span className="text-sm">{name}</span>
      </td>

      {isAcrescimo ? (
        <>
          <td
            className={classNames(
              isAcrescimo ? "" : "",
              "border-b px-3 py-2 border-slate-500",
              classNameTd ?? ""
            )}
          >
            {porcentagemAtual < 0 ? "-" : porcentagemAtual + "%"}
          </td>

          <td
            className={classNames(
              isAcrescimo ? "" : "",
              "border-b px-3 py-2 border-slate-500",
              classNameTd ?? ""
            )}
          >
            {porcentagemNovembro} %
          </td>
          <td
            className={classNames(
              isAcrescimo ? "" : "",
              "border-b px-3 py-2 border-slate-500",
              classNameTd ?? ""
            )}
          >
            {porcentagemAnterior} %
          </td>
        </>
      ) : (
        <>
          <td
            className={classNames(
              isAcrescimo ? "" : "",
              "border-b px-3 py-2 border-slate-500",
              classNameTd ?? ""
            )}
          >
            {porcentagemAtual} %
            {/* <div className="pl-4 inline-block">
          <IndiceVariationIcon isHigher={verifyHigher} />
          <span className="text-sm font-medium">{porcentagemAnterior} %</span>
        </div> */}
          </td>
          <td
            className={classNames(
              isAcrescimo ? "" : "",
              "border-b px-3 py-2 border-slate-500 text-sm font-medium",
              classNameTd ?? ""
            )}
          >
            <div>
              {medicaoIPv6}{" "}
              {medicaoIPv6 > 1 || medicaoIPv6 == 0 ? "medições" : "medição"} com
              IPv6
            </div>
            <div>
              {medicaoIPv4}{" "}
              {medicaoIPv4 > 1 || medicaoIPv4 == 0 ? "medições" : "medição"} com
              somente IPv4
            </div>
          </td>
        </>
      )}
      <td
        className={classNames(
          isAcrescimo ? "" : "",
          "border-b px-3 py-2 border-slate-500 text-sm font-medium w-48",
          classNameTd ?? ""
        )}
      >
        {conformeRegulamento ? (
          <CheckCircleIcon className="h-8 w-8 text-green-500" />
        ) : (
          <BanIcon className="h-8 w-8 text-red-500" />
        )}
      </td>
    </tr>
  )
}

export default LeaderBoardRow
