import React, { FunctionComponent } from "react"
import LeaderBoardHeader from "./leaderBoardHeader"
import { LeaderboardDataType, LeaderboardType } from "./leaderBoardModel"
import LeaderBoardTable from "./table"
import { useQuery } from "urql"
import { useKeycloak } from "@react-keycloak/web"
import { AutonomousSystem } from "@models/autonomous-system"
import { parseISO, add, format } from "date-fns"
import CSVReportDownload from "@components/csv-report/download"
import { DownloadIcon } from "@heroicons/react/solid"

interface Props {
  availableAutonoumsSystem: AutonomousSystem[] | null
  leaderboard: LeaderboardType
}

const headers = [
  { label: "Posição", key: "posicao" },
  { label: "Asn", key: "asn" },
  { label: "Nome Provedor", key: "name" },
  { label: "Porcentagem Atual", key: "porcentagemAtual" },
  { label: "Porcentagem Anterior", key: "porcentagemAnterior" },
  { label: "Porcentagem Novembro", key: "porcentagemNovembro" },
  { label: "Medição IPv6", key: "medicaoIPv6" },
  { label: "Medição IPv4", key: "medicaoIPv4" },
  { label: "Conforme Regulamento", key: "measurementObey" },
]

const LeaderBoard: FunctionComponent<Props> = ({
  availableAutonoumsSystem,
  leaderboard,
}) => {
  const { initialized: isKeycloakInitialized } = useKeycloak()

  const [result, _] = useQuery({
    query: `
      query($leaderboardType: LeaderBoardType!){
        leaderboard(leaderboardType: $leaderboardType) {
          lastUpdate
          nodes {
            posicao
            asn
            name
            porcentagemAtual
            porcentagemAnterior
            porcentagemNovembro
            medicaoIPv6
            medicaoIPv4
            conformeRegulamento
          }
        }
      }
    `,
    variables: {
      leaderboardType: leaderboard,
    },
    pause: !isKeycloakInitialized,
  })

  const { data, fetching, error } = result

  if (error) console.error(error)

  let ultimaAtualizacao = undefined
  let proximaAtualizacao = undefined
  let filenameDate = undefined

  if (!fetching && data?.leaderboard.lastUpdate !== undefined) {
    ultimaAtualizacao = parseISO(data?.leaderboard.lastUpdate)
    proximaAtualizacao = add(ultimaAtualizacao, { days: 7 })
    filenameDate = format(ultimaAtualizacao, "dd-MM-yyyy")
  }

  const formatLeaderboardTypeForReport = `Implantação ${
    leaderboard === "ACRESCIMO" ? "Nova" : "Total"
  }`

  const filenameReport = `Report Prêmio IPv6 2023 ${formatLeaderboardTypeForReport} - ${
    filenameDate ?? " "
  }.csv`

  const transformData =
    (data?.leaderboard?.nodes as LeaderboardDataType[])?.map((res) => {
      return {
        ...res,
        measurementObey: res.conformeRegulamento === "sim",
      }
    }) ?? []

  return (
    <div className="inline-block min-w-full py-2 align-middle">
      <div className="-mt-3">
        <LeaderBoardHeader leaderBoard={leaderboard} />
        <div className="text-sm grid grid-cols-2 text-gray-600 gap-x-3 my-2">
          <div className="col-start-3 flex flex-col leading-tight">
            <CSVReportDownload
              data={transformData}
              headers={headers}
              filename={filenameReport}
              target={"_blank"}
              separator={";"}
              uFEFF={true}
              enclosingCharacter='"'
              asyncOnClick={false}
              onClick={() => {}}
            >
              <span className="inline-flex items-center h-9 px-3 text-right text-sm rounded-full font-semibold focus:outline-none focus:ring-2 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 focus:ring-blue-500">
                <DownloadIcon className="h-5 w-5 mr-2 flex-none" />
                Download Report
              </span>
            </CSVReportDownload>
          </div>
        </div>
        <div className="text-sm grid grid-cols-2 text-gray-600 gap-x-3 my-2">
          <div className="col-start-1 flex flex-col leading-tight">
            <span>
              Última Atualização:{" "}
              {ultimaAtualizacao ? format(ultimaAtualizacao, "dd-MM-yyyy") : ""}
            </span>
            {/* <span>
              Próxima Atualização:{" "}
              {proximaAtualizacao
                ? format(proximaAtualizacao, "dd-MM-yyyy")
                : ""}
            </span> */}
          </div>
          <div className="col-start-3 flex flex-col leading-tight items-end">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 text-green-500 inline-block"
              >
                <path
                  fillRule="evenodd"
                  d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                  clipRule="evenodd"
                />
              </svg>
              Comparação da semana anterior
            </span>
            <div className="flex space-x-1">
              <div className="">*</div>
              <div className="flex flex-col leading-none">
                <div>1.000 medições (200 IPv6) em novembro</div>
                <div>2.000 medições de agosto a novembro</div>
              </div>
            </div>
          </div>
        </div>
        <LeaderBoardTable
          data={data?.leaderboard?.nodes}
          loading={!isKeycloakInitialized && !fetching}
          leaderboard={leaderboard}
          availableAutonoumsSystem={availableAutonoumsSystem}
        />
      </div>
    </div>
  )
}

export default LeaderBoard
