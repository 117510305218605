import React, { FunctionComponent, memo } from "react"
import { LeaderboardType } from "./leaderBoardModel"

interface PropsButton {
  onClick: () => void
  isActive: boolean
  text?: string
}

interface PropsHeader {
  leaderBoard: LeaderboardType
}

const LeaderBoardHeader: FunctionComponent<PropsHeader> = ({ leaderBoard }) => {
  const isAcrescimo = leaderBoard === "ACRESCIMO"

  return (
    <div className="flex content-center text-center my-4">
      <div className="group text-slate-600 font-bold space-x-2 w-full justify-between">
        <div className="text-lg">
          {isAcrescimo
            ? "Prêmio 2 - Implantação Nova"
            : "Prêmio 1 - Maior Implantação"}
        </div>
        <span className="text-sm">
          Considerando Medições SIMET <span className="">em nov.2023</span>
        </span>
      </div>
    </div>
  )
}

export default memo(LeaderBoardHeader)
