import { useKeycloak } from "@react-keycloak/web"
import React, { Dispatch, FunctionComponent, useCallback } from "react"
import { useDispatch } from "react-redux"
import urlJoin from "url-join"
import { ReducerAction } from "@store/index"
import { SET_SELECTED_ASN } from "@store/navigation-store"
import AutonomousSystemSelector from "../controls/autonomous-system-selector"
import PasIcon from "@icons/pas-icon.svg"
import { RESOURCE_ADMIN_SCOPE } from "@models/scope-description"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
import NavBarLinks from "./sidebar-nav-bar-links"
import { PageMetadataObject } from "@models/page-metadata-type"
import { navigate } from "gatsby"

interface Props {
  isMobile: boolean
  externalServices: {
    anomalyDetection?: {
      baseUrl: string
    }
    asMeasurements?: {
      baseUrl: string
    }
    simetAs?: {
      baseUrl: string
    }
    asManagement?: {
      baseUrl: string
    }
  }
  pageMetadata: PageMetadataObject
  scopeMappingList: AutonomousSystemScopeMapping[] | null
}

const SidebarRouter: FunctionComponent<Props> = ({
  isMobile,
  externalServices,
  pageMetadata,
  scopeMappingList,
}) => {
  /* const anomalyDetectionBaseUrl =
    externalServices?.anomalyDetection?.baseUrl || "/" */

  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()

  const suffix = "/" + pageMetadata.page + "/" + pageMetadata.pageCategory
  const currentPageScope = pageMetadata.pageScope
  const currentApp = pageMetadata.appName

  const AutonomousSystemCallback = useCallback(
    (asn: number | undefined) => {
      let url = ""
      if (currentPageScope === "as-scoped") {
        if (asn) {
          console.log("Navigating to new ASN", asn)
          url = urlJoin(
            "/",
            currentApp?.toString() ?? "",
            "/AS" + asn,
            suffix ?? ""
          )
        } else {
          url = urlJoin("/", currentApp?.toString() ?? "", "/", suffix ?? "")
        }
        dispatch({ type: SET_SELECTED_ASN, selectedAsn: asn })
        navigate(url)
      }
    },
    [currentPageScope, currentApp, suffix, dispatch]
  )

  let defaultUrl = false
  // const [currentAsn, setCurrentAsn] = useState<string>("")
  if (
    pageMetadata == null ||
    pageMetadata.asn == null ||
    pageMetadata.asn < 0
  ) {
    defaultUrl = true
  }
  const isAdminASN = scopeMappingList
    ? (scopeMappingList
        .filter((asnMapping) => {
          return asnMapping.resource.asn === pageMetadata.asn
        })
        .findIndex((asAdminRole) =>
          asAdminRole.scopes.includes(RESOURCE_ADMIN_SCOPE)
        ) ?? 0) !== -1
    : false

  const isAdmin = keycloak.hasRealmRole("admin")

  return (
    <div
      className={
        isMobile
          ? "flex-1 h-0 pt-6 pb-4 overflow-y-auto"
          : "flex-1 flex flex-col pb-4 overflow-y-auto"
      }
    >
      <div className="flex items-center flex-shrink-0 px-6">
        {/* redirect para landing page */}
        <a href={"/"} className="w-full">
          <div
            className="flex flex-shrink-0 items-stretch text-blue-600 "
            style={{ filter: "saturate(80%)" }}
          >
            <PasIcon className="h-11 w-auto fill-current" />
            <div className="ml-2">
              <div className="text-3xl font-bold leading-none tracking-tight">
                PAS
              </div>
              <div className="-mt-1 leading-snug leading-snug font-semibold">
                Portal do AS
              </div>
            </div>
            <div
              className="flex items-end justify-end flex-grow leading-snug text-sm font-semibold"
              style={{ justifyContent: "flex-end" }}
            >
              v.0.10
            </div>
          </div>
        </a>
      </div>

      {/* AS Selection */}
      {isKeycloakInitialized && keycloak?.authenticated ? (
        <>
          {/* <AvailableAutonomousSystemService /> */}
          {
            <div className="mt-8 inline-block px-4 text-left w-full">
              <div>
                <AutonomousSystemSelector
                  onSelectAutonomousSystem={(asn) => {
                    console.log("Navigating to new ASN", asn)
                    // dispatch({ type: SET_SELECTED_ASN, selectedAsn: asn })
                    let link = ""

                    if (currentPageScope === "as-scoped") {
                      // window.location.assign(urlJoin('/', currentApp?.toString() ?? '', '/AS' + asn, suffix ?? ''))
                      if (pageMetadata.pageSubcategory === "management") {
                        link = `/${
                          currentApp?.toString() ?? ""
                        }/management/AS${asn}${suffix ?? ""}`
                      } else {
                        link = `/${currentApp?.toString() ?? ""}/AS${asn}${
                          suffix ?? ""
                        }`
                      }
                      navigate(link)
                    }
                  }}
                  onClearAutonomousSystem={() => {
                    dispatch({ type: SET_SELECTED_ASN, selectedAsn: undefined })
                    if (currentPageScope === "as-scoped") {
                      // window.location.assign(urlJoin(currentApp?.toString() ?? '', "/", suffix ?? ""))
                      navigate(
                        `${currentApp?.toString() ?? ""}/${suffix ?? ""}`
                      )
                      // navigate(""), {
                      //   state: { beginExtended: true },
                      // })
                    }
                  }}
                  asnScopedPage={pageMetadata.pageScope === "as-scoped"}
                />
              </div>
            </div>
          }
          <NavBarLinks
            externalServices={externalServices}
            pageMetadata={pageMetadata}
            menuCompleto={isAdmin || isAdminASN}
            urlDefault={defaultUrl}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
export default SidebarRouter
