import React, { FunctionComponent } from "react"

const LeaderBoardTableHeader: FunctionComponent<{ columns: JSX.Element[] }> = ({
  columns,
}) => {
  return (
    <thead className="bg-white shadow text-slate-900">
      <tr>
        {columns.map((column, columnIdx) => (
          <th
            key={columnIdx}
            scope="col"
            className={
              "sticky top-0 z-10 border-b border-slate-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter"
            }
          >
            {column}
          </th>
        ))}
        <th
          scope="col"
          className={
            "sticky top-0 z-10 border-b border-slate-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter"
          }
        >
          <span className="flex leading-tight">
            Volume de Medição Atende Regulamento *
          </span>
        </th>
      </tr>
    </thead>
  )
}

export default LeaderBoardTableHeader
