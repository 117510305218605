import React, { ReactElement, useEffect } from "react"
import Helmet from "react-helmet"
import { PageProps } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { ReducerAction, ReducerState } from "@store/index"
import RequireAuthentication from "@components/shared/require-authentication"
import LeaderBoard from "@components/leaderboard"
import { DELETE_ALERT } from "@store/navigation-store"
import { AutonomousSystem } from "@models/autonomous-system"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import { PageDocsSidebarContentGeneric } from "@models/page-docs-sidebar-content-type"
import { PageMetadataObject } from "@models/page-metadata-type"
import ScrollTop from "@components/shared/scroll-top"

export interface IndexLocationState {
  availableAutonomousSystems: AutonomousSystem[]
  pageMetadata: PageMetadataObject
}

interface ReducerSelected {
  availableAutonomousSystems: AutonomousSystem[] | null
}

const isBrowser = typeof window !== "undefined"

const LeaderBoardPage: (
  _: PageProps<object, object, IndexLocationState> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  const asn = useAsnParameter(asnString)
  const dispatch = useDispatch<Dispatch<ReducerAction>>()

  useEffect(() => {
    // Clear error
    dispatch({
      type: DELETE_ALERT,
      alert: null,
    })

    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { availableAutonomousSystems } = useSelector<
    ReducerState,
    ReducerSelected
  >((state) => {
    return {
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
    }
  })

  let pageMetadata = {
    appName: "premio-ipv6-nicbr-2023",
    page: "",
    pageCategory: "",
  }

  if (isBrowser) {
    const pathName = location.pathname
    const pathNameArray = pathName.split("/").filter((element) => {
      return element.length > 0 && typeof element == "string"
    })

    if (pathNameArray[0].includes("premio")) {
      pageMetadata = {
        appName: "premio-ipv6-nicbr-2023",
        page: "premio-ipv6-nicbr-2023",
        pageCategory: pathNameArray[1].includes("total") ? "total" : "nova",
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>PAS - Leaderboard IPv6</title>
      </Helmet>
      <RequireAuthentication className="min-h-full relative">
        <ScrollTop />
        <div className="pt-6 px-4 sm:px-6 lg:px-8 h-full">
          <div className="contents">
            {/* <AvailableAutonomousSystemService /> */}
            <LeaderBoard
              availableAutonoumsSystem={availableAutonomousSystems}
              leaderboard={
                pageMetadata.pageCategory === "nova"
                  ? "ACRESCIMO"
                  : "IMPLANTACAO"
              }
            />
          </div>
        </div>
      </RequireAuthentication>
    </>
  )
}

export default LeaderBoardPage

export const getPageSidebarContent = (): PageDocsSidebarContentGeneric => {
  return {
    title: "Gerenciamento de servidores",
    abstract:
      "Gerenciar os servidores Simet instalados na rede do seu sistema autônomo",
    content: <div></div>,
  }
}
