import React, { FunctionComponent } from "react"
import LeaderBoardTableRow from "./leaderBoardTableRow"
import LeaderBoardTableHeader from "./leaderBoardTableHeader"
import { LeaderboardDataType, LeaderboardType } from "../leaderBoardModel"
import PageSpinner from "@components/shared/page-spinner"
import { AutonomousSystem } from "@models/autonomous-system"

interface Props {
  data: LeaderboardDataType[] | undefined
  loading: boolean
  leaderboard: LeaderboardType
  availableAutonoumsSystem: AutonomousSystem[] | null
}

const LeaderBoardTable: FunctionComponent<Props> = ({
  data,
  loading,
  leaderboard,
  availableAutonoumsSystem,
}) => {
  const isAcrescimo = leaderboard === "ACRESCIMO"

  let tableColumns: JSX.Element[] = []

  if (isAcrescimo) {
    tableColumns = [
      <div className="flex"> </div>,
      <div className="flex"> Participante</div>,
      <div>
        {" "}
        Implantação Nova de IPv6{" "}
        {/* <span className=" text-blue-500"> desde ago. 2023 </span>{" "} */}
      </div>,
      <div>
        {" "}
        Implantação Final <span className=""> (nov. 2023) </span>{" "}
      </div>,
      <div>
        {" "}
        Implantação Inicial <span className=""> (jan-jul. 2023)</span>{" "}
      </div>,
      //   <div>
      //     {" "}
      //     Medições (com IP distinto){" "}
      //     <span className="flex text-blue-500"> desde ago. 2023 </span>{" "}
      //   </div>,
    ]
  } else {
    tableColumns = [
      <div className="flex"></div>,
      <div className="flex"> Participante</div>,
      <div>
        {" "}
        Implantação de IPv6 <span className=""> em nov. 2023 </span>{" "}
      </div>,
      <div>
        {" "}
        Medições (com IP distinto) <span className="flex">
          {" "}
          em nov. 2023{" "}
        </span>{" "}
      </div>,
    ]
  }

  const tableRowsSorted = data?.sort((a, b) => a.posicao - b.posicao) ?? []

  const renderAvailableAutonomousSystemByPosition = tableRowsSorted.map(
    (row) => (
      <LeaderBoardTableRow
        posicao={row.posicao}
        asn={row.asn}
        name={row.name}
        porcentagemAtual={row.porcentagemAtual}
        porcentagemAnterior={row.porcentagemAnterior}
        medicaoIPv6={row.medicaoIPv6}
        medicaoIPv4={row.medicaoIPv4}
        isAcrescimo={isAcrescimo}
        active={
          availableAutonoumsSystem?.findIndex(
            (autonomous) => autonomous.asn === row.asn
          ) !== -1
        }
        conformeRegulamento={row.conformeRegulamento === "sim"}
        porcentagemNovembro={row.porcentagemNovembro}
      />
    )
  )
  return (
    <>
      <table
        className="min-w-full border-separate table-auto w-full"
        style={{ borderSpacing: 0 }}
      >
        <LeaderBoardTableHeader columns={tableColumns} />
        <tbody className="bg-white/25">
          {/* TODO: centralizar PageSpinner no meio da tabela */}
          {loading ? (
            <PageSpinner />
          ) : (
            <>{renderAvailableAutonomousSystemByPosition}</>
          )}
        </tbody>
      </table>
    </>
  )
}

export default LeaderBoardTable
