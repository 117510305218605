exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-autonomous-systems-tsx": () => import("./../../../src/pages/account/autonomous-systems.tsx" /* webpackChunkName: "component---src-pages-account-autonomous-systems-tsx" */),
  "component---src-pages-isp-user-portal-confirmation-tsx": () => import("./../../../src/pages/isp-user-portal/confirmation.tsx" /* webpackChunkName: "component---src-pages-isp-user-portal-confirmation-tsx" */),
  "component---src-pages-isp-user-portal-index-tsx": () => import("./../../../src/pages/isp-user-portal/index.tsx" /* webpackChunkName: "component---src-pages-isp-user-portal-index-tsx" */),
  "component---src-pages-isp-user-portal-licenses-tsx": () => import("./../../../src/pages/isp-user-portal/licenses.tsx" /* webpackChunkName: "component---src-pages-isp-user-portal-licenses-tsx" */),
  "component---src-pages-isp-user-portal-settings-user-as-tsx": () => import("./../../../src/pages/isp-user-portal/settings-user-as.tsx" /* webpackChunkName: "component---src-pages-isp-user-portal-settings-user-as-tsx" */),
  "component---src-pages-isp-user-portal-support-tsx": () => import("./../../../src/pages/isp-user-portal/support.tsx" /* webpackChunkName: "component---src-pages-isp-user-portal-support-tsx" */),
  "component---src-pages-isp-user-portal-terms-tsx": () => import("./../../../src/pages/isp-user-portal/terms.tsx" /* webpackChunkName: "component---src-pages-isp-user-portal-terms-tsx" */),
  "component---src-pages-management-users-tsx": () => import("./../../../src/pages/management/users.tsx" /* webpackChunkName: "component---src-pages-management-users-tsx" */),
  "component---src-pages-measurements-dashboard-tsx": () => import("./../../../src/pages/measurements/dashboard.tsx" /* webpackChunkName: "component---src-pages-measurements-dashboard-tsx" */),
  "component---src-pages-measurements-measurements-tsx": () => import("./../../../src/pages/measurements/measurements.tsx" /* webpackChunkName: "component---src-pages-measurements-measurements-tsx" */),
  "component---src-pages-premio-bcp-nicbr-2024-regulamento-tsx": () => import("./../../../src/pages/premio-bcp-nicbr-2024/regulamento.tsx" /* webpackChunkName: "component---src-pages-premio-bcp-nicbr-2024-regulamento-tsx" */),
  "component---src-pages-premio-ipv-6-nicbr-2023-index-tsx": () => import("./../../../src/pages/premio-ipv6-nicbr-2023/index.tsx" /* webpackChunkName: "component---src-pages-premio-ipv-6-nicbr-2023-index-tsx" */),
  "component---src-pages-simet-as-antispoofing-tsx": () => import("./../../../src/pages/simet-as/antispoofing.tsx" /* webpackChunkName: "component---src-pages-simet-as-antispoofing-tsx" */),
  "component---src-pages-simet-as-devices-tsx": () => import("./../../../src/pages/simet-as/devices.tsx" /* webpackChunkName: "component---src-pages-simet-as-devices-tsx" */),
  "component---src-pages-simet-as-ixbr-tsx": () => import("./../../../src/pages/simet-as/ixbr.tsx" /* webpackChunkName: "component---src-pages-simet-as-ixbr-tsx" */)
}

