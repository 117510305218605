import { PageMetadataObject } from "@models/page-metadata-type"
import { AutonomousSystem } from "@models/autonomous-system"
import { PageDocsSidebarContentType } from "@models/page-docs-sidebar-content-type"
import urlJoin from "url-join"

const isBrowser = typeof window !== "undefined"

export function parseURLtoPageMetadata(
  availableAutonomousSystems: AutonomousSystem[] | null,
  keycloak: any
) {
  let pageMetadata: PageMetadataObject
  if (isBrowser) {
    const pathName = window.location.pathname

    const storedASString: string | null = localStorage.getItem("asn")
    let storedASN: number
    const isAdmin = keycloak?.hasRealmRole("admin")
    if (storedASString !== null) {
      storedASN = parseInt(
        storedASString.toLowerCase().replace("as", "").replace("asn", "")
      )
    } else if (isAdmin) {
      storedASN = 14026
    } else if (
      availableAutonomousSystems !== null &&
      availableAutonomousSystems.length > 0
    ) {
      storedASN = availableAutonomousSystems[0].asn
    } else {
      storedASN = -1
    }
    const pathNameArray = pathName.split("/").filter((element) => {
      return element.length > 0 && typeof element == "string"
    })
    let scoped: boolean = false
    if (pathNameArray.length == 4) {
      if (pathNameArray[1].toLocaleLowerCase().startsWith("as")) {
        scoped = true
      }
      if (scoped) {
        pageMetadata = {
          appName: pathNameArray[0],
          asn: parseInt(
            pathNameArray[1].toLowerCase().replace("asn", "").replace("as", "")
          ),
          page: pathNameArray[2],
          pageCategory: pathNameArray[3],
          pageSubcategory: pathNameArray[4],
          pageScope: "as-scoped",
          urlBase: "",
          isPending: false,
          isReady: true,
        }
      } else {
        pageMetadata = {
          appName: pathNameArray[0],
          asn: storedASN,
          page: pathNameArray[1],
          pageCategory: pathNameArray[2],
          pageSubcategory: pathNameArray[3],
          pageScope: "global-scoped",
          urlBase: "",
          isPending: false,
          isReady: true,
        }
      }
    }
    // else if (pathNameArray[0].includes("premio")) {
    //   pageMetadata = {
    //     appName: "premio-ipv6-nicbr-2023",
    //     asn: storedASN,
    //     page: "premio-ipv6-nicbr-2023",
    //     pageCategory: pathNameArray[1].includes("total") ? "total" : "nova",
    //     pageSubcategory: "",
    //     pageScope: "global-scoped",
    //     urlBase: "",
    //     isPending: false,
    //     isReady: true,
    //   }
    // }
    else if (pathNameArray.length == 2) {
      pageMetadata = {
        appName: "management",
        asn: storedASN,
        page: pathNameArray[0],
        pageCategory: pathNameArray[1],
        pageSubcategory: pathNameArray[2],
        pageScope: "global-scoped",
        urlBase: "",
        isPending: false,
        isReady: true,
      }
    } else if (pathNameArray.length == 5) {
      pageMetadata = {
        appName: pathNameArray[0],
        asn: parseInt(
          pathNameArray[2].toLowerCase().replace("as", "").replace("asn", "")
        ),
        page: pathNameArray[3],
        pageCategory: pathNameArray[4],
        pageSubcategory: pathNameArray[1],
        pageScope: "as-scoped",
        urlBase: "",
        isPending: false,
        isReady: true,
      }
    } else {
      // ver com o rodrigo app name nao deveria ser pathNameArray[0]
      // page scope ?
      pageMetadata = {
        appName: "management",
        asn: storedASN,
        page: pathNameArray[0],
        pageCategory: pathNameArray[1],
        pageSubcategory: pathNameArray[2],
        pageScope: "global-scoped",
        urlBase: "",
        isPending: false,
        isReady: true,
      }
    }
  } else {
    pageMetadata = {
      appName: "",
      asn: -1,
      page: "",
      pageCategory: "",
      pageSubcategory: "",
      pageScope: "global-scoped",
      urlBase: "/",
      isPending: false,
      isReady: true,
    }
  }
  var pageModule: PageDocsSidebarContentType | undefined
  const pageName =
    pageMetadata.page === "account" ? "account" : pageMetadata.appName

  try {
    pageModule =
      require(`../pages/${pageName}/${pageMetadata.pageCategory}`).getPageSidebarContent()
  } catch (error) {
    pageModule = undefined
    // console.error(`
    //   Não foi possível importar o modulo webapp/src/pages/${pageMetadata.pageCategory}.
    //   Favor verificar, se foi digitado corretamente o nome da página no gatsby com o nome do pageCategory = ${pageMetadata.pageCategory}, \
    //   com isso será possivel obter os valores da função getPageSidebarContent, disponível em cada página.
    // `)

    // console.error(`
    //   Tentando o modulo webapp/src/pages/${pageMetadata.page}, com o valor page = ${pageMetadata.page}
    // `)

    try {
      pageModule =
        require(`../pages/${pageName}/${pageMetadata.page}`).getPageSidebarContent()
    } catch (error) {
      pageModule = undefined
      //   console.error(`
      //   Não foi possível importar o modulo webapp/src/pages/${pageMetadata.page}.
      //   Favor verificar, se foi digitado corretamente o nome da página no gatsby com o nome do page = ${pageMetadata.page}, \
      //   com isso será possivel obter os valores da função getPageSidebarContent, disponível em cada página.
      // `)
    }
  }

  pageMetadata.urlBase = urlJoin(window.origin, pageMetadata.appName)

  pageMetadata.pageDocsSidebarContent = pageModule

  return pageMetadata
}
