import React, { FunctionComponent } from "react"
import {
  UsersIcon,
  SwitchHorizontalIcon,
  ServerIcon,
  ExclamationCircleIcon,
  CubeTransparentIcon,
  BadgeCheckIcon,
  ChartSquareBarIcon,
  BookOpenIcon,
} from "@heroicons/react/outline"
import { HeaderType, HeroIcon } from "@_types/header-type"
import Icon3DStack from "@icons/Square3Stack3DIcon.svg"
import HeaderLink from "./header-link"
import { PageMetadataObject } from "@models/page-metadata-type"

type CustomProps = {
  pageMetadata: PageMetadataObject
}

type FCWithCustomProps<P = {}> = FunctionComponent<P & CustomProps>

const HeaderPageNavigation: FCWithCustomProps = ({ pageMetadata }) => {
  let headers: HeaderType[] = []

  switch (pageMetadata.page) {
    case "dashboard":
      switch (pageMetadata.pageCategory) {
        case "timeseries":
          headers.push({
            icon: ChartSquareBarIcon as HeroIcon,
            link: `/measurements/AS${pageMetadata.asn}/dashboard/timeseries/`,
            name: "Dashboard",
            active: true,
          })
          break
      }
      break
    case "measurements":
      switch (pageMetadata.pageCategory) {
        case "measurements":
          headers.push({
            icon: SwitchHorizontalIcon as HeroIcon,
            link: `/measurements/AS${pageMetadata.asn}/measurements/measurements/`,
            name: "Medições",
            active: true,
          })
          break
      }
      break
    case "ixbr":
      switch (pageMetadata.pageCategory) {
        case "connection":
          headers.push({
            icon: CubeTransparentIcon as HeroIcon,
            link: `/simet-as/AS${pageMetadata.asn}/ixbr/connection/`,
            name: "IX.br",
            active: true,
          })
          break
      }
      break
    case "compliance":
      switch (pageMetadata.pageCategory) {
        case "antispoofing":
          headers.push({
            icon: BadgeCheckIcon as HeroIcon,
            link: `/simet-as/AS${pageMetadata.asn}/compliance/antispoofing/`,
            name: "Conformidade",
            active: true,
          })
          break
      }
      break
    case "devices":
      switch (pageMetadata.pageCategory) {
        case "servers":
          headers.push({
            icon: ServerIcon as HeroIcon,
            link: `/simet-as/management/AS${pageMetadata.asn}/devices/servers/`,
            name: "Servidores",
            active: true,
          })
          break
      }
    case "users":
      switch (pageMetadata.pageCategory) {
        case "permissions":
          headers.push({
            icon: UsersIcon as HeroIcon,
            link: `/management/AS${pageMetadata.asn}/users/permissions/`,
            name: "Usuários PAS",
            active: true,
          })
          break
        default:
          break
      }
      break
    case "account":
      headers.push({
        icon: Icon3DStack as HeroIcon,
        link: `/account/autonomous-systems/`,
        name: "Sistemas Autônomos",
        active: true,
      })
      break
    case "premio-bcp-nicbr-2024":
      headers.push({
        icon: BookOpenIcon as HeroIcon,
        link: `/premio-bcp-nicbr-2024/regulamento/`,
        name: "Regulamento",
        active: true,
      })
      //   headers.push({
      //     icon: Icon3DStack as HeroIcon,
      //     link: `/premio-ipv6-nicbr-2023/implantacao-nova/`,
      //     name: "Prêmio 2",
      //     active: pageMetadata.pageCategory == "nova",
      //   })
      break
    default:
      let erro = "error"
      headers.push({
        icon: ExclamationCircleIcon as HeroIcon,
        link: "#",
        name: "Erro",
        active: true,
      })
      break
  }

  return (
    <>
      {headers?.map((res, idx) => {
        // const url = urlJoin(pageMetadata.urlBase, res.link)
        return (
          <HeaderLink
            key={idx}
            to={res.link}
            isActive={res.active}
            isInternal={true}
          >
            <>
              <res.icon className="mr-2 h-5 w-5 text-slate-600" />
              {res.name}
            </>
          </HeaderLink>
        )
      })}
    </>
  )
}
export default HeaderPageNavigation
