import React, { FunctionComponent, memo } from "react"
import SidebarLink from "./sidebar-link"
import {
  SwitchHorizontalIcon,
  //   CubeTransparentIcon,
  //   BadgeCheckIcon,
  UserGroupIcon,
  ChartSquareBarIcon,
  ServerIcon,
  BookOpenIcon,
} from "@heroicons/react/outline"
import { PageMetadataObject } from "@models/page-metadata-type"

interface Props {
  externalServices: {
    anomalyDetection?: {
      baseUrl: string
    }
    asMeasurements?: {
      baseUrl: string
    }
    simetAs?: {
      baseUrl: string
    }
    asManagement?: {
      baseUrl: string
    }
  }
  pageMetadata: PageMetadataObject
  menuCompleto: boolean
  urlDefault: boolean
}

const NavBarLinks: FunctionComponent<Props> = ({
  externalServices,
  pageMetadata,
  menuCompleto,
  urlDefault,
}) => {
  const asAccountAutonomousSystemsBaseUrl = "/account/autonomous-systems/"

  const suffix = "/" + pageMetadata.page + "/" + pageMetadata.pageCategory
  const currentApp = pageMetadata.appName

  const asn = "AS" + pageMetadata.asn

  return (
    <>
      <nav className="px-4 pt-2">
        {/* <h3 className="mt-8 mb-2 text-xs font-semibold uppercase tracking-wider text-slate-400">
          <SidebarLink
            to={
              urlDefault
                ? asAccountAutonomousSystemsBaseUrl
                : "/premio-bcp-nicbr-2024/regulamento/"
            }
            isActive={currentApp === "premio-bcp-nicbr-2024"}
            isInternal={true}
            className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
            activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-6 w-6 flex-shrink-0"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="8" r="7" />
              <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
            </svg>
            Prêmio IPV6 - NIC.br
          </SidebarLink>
        </h3> */}
        <h3 className="mt-8 mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-slate-400">
          Prêmio BCP 2024
        </h3>
        <div className="space-y-0">
          <SidebarLink
            to={
              urlDefault
                ? asAccountAutonomousSystemsBaseUrl
                : `/premio-bcp-nicbr-2024/${asn}/regulamento/`
            }
            isActive={suffix === `/premio-bcp-nicbr-2024/${asn}`}
            isInternal={true}
            className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
            activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
          >
            <BookOpenIcon className="mr-2 h-6 w-6 flex-shrink-0" />
            Regulamento
          </SidebarLink>
          {/* <SidebarLink
            to={
              urlDefault
                ? asAccountAutonomousSystemsBaseUrl
                : `/measurements/${asn}/measurements/measurements/`
            }
            isActive={
              currentApp === "measurements" &&
              suffix === "/measurements/measurements"
            }
            isInternal={true}
            className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
            activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
          >
            <SwitchHorizontalIcon className="mr-2 h-6 w-6 flex-shrink-0" />
            Medições
          </SidebarLink> */}
        </div>
        <h3 className="mt-8 mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-slate-400">
          Experiência do Usuário
        </h3>
        <div className="space-y-0">
          <SidebarLink
            to={
              urlDefault
                ? asAccountAutonomousSystemsBaseUrl
                : `/measurements/${asn}/dashboard/timeseries/`
            }
            isActive={
              currentApp === "measurements" &&
              suffix === "/dashboard/timeseries"
            }
            isInternal={true}
            className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
            activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
          >
            <ChartSquareBarIcon className="mr-2 h-6 w-6 flex-shrink-0" />
            Dashboard
          </SidebarLink>
          <SidebarLink
            to={
              urlDefault
                ? asAccountAutonomousSystemsBaseUrl
                : `/measurements/${asn}/measurements/measurements/`
            }
            isActive={
              currentApp === "measurements" &&
              suffix === "/measurements/measurements"
            }
            isInternal={true}
            className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
            activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
          >
            <SwitchHorizontalIcon className="mr-2 h-6 w-6 flex-shrink-0" />
            Medições
          </SidebarLink>
        </div>

        {/* <h3 className="mt-8 mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-slate-400">
              Roteamento Inter-AS
            </h3>
            <div className="space-y-0">
              <SidebarLink
                to=""
                isInternal={false}
                className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
                activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
              >
                <SupportIcon className="mr-2 h-6 w-6 flex-shrink-0" />
                Tabela de roteamento
              </SidebarLink>
            </div> */}
        {menuCompleto && (
          <>
            <h3 className="mt-8 mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-slate-400">
              Gerenciamento
            </h3>

            <div className="space-y-0">
              <SidebarLink
                to={`/simet-as/management/${asn}/devices/servers/`}
                isActive={
                  currentApp === "simet-as" && suffix === "/devices/servers"
                }
                isInternal={true}
                className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
                activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
              >
                <ServerIcon className="mr-2 h-6 w-6 flex-shrink-0" />
                Servidores
              </SidebarLink>
              <SidebarLink
                to={`/management/${asn}/users/permissions/`}
                isActive={
                  currentApp === "management" && suffix === "/users/permissions"
                }
                isInternal={true}
                className="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-slate-600 hover:text-black hover:font-semibold"
                activeClassName="group flex items-center rounded-md px-2 py-2 text-sm font-semibold text-blue-600"
              >
                <UserGroupIcon className="mr-2 h-6 w-6 flex-shrink-0" />
                Usuários PAS
              </SidebarLink>
            </div>
          </>
        )}
      </nav>
    </>
  )
}

export default memo(NavBarLinks)
